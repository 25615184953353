/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Roboto */
.roboto-thin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: normal;
}
.roboto-light {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.roboto-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}
.roboto-black {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
}
.roboto-thin-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: italic;
}
.roboto-light-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
}
.roboto-regular-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
}
.roboto-medium-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
}
.roboto-bold-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
}
.roboto-black-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
}

/* Gochi Hand */
.gochi-hand {
  font-family: 'Gochi Hand', cursive;
  font-weight: 400;
  font-style: normal;
}

/* Variables */
:root {
  --background: #fff;
  --text: #000;
  --shadow: rgba(0, 0, 0, 0.5);
  --border: #ccc;
  --card: rgba(0, 0, 0, 0.025);
  --card-alt: rgba(0, 0, 0, 0.05);
}

/* Styles */

/* All */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-weight: 300;
}

/* Body */
body {
  background-color: var(--background);
  color: var(--text);
}

/* Typography */
h1 {
  font-size: 48px;
  font-weight: 500;
}
h2 {
  font-size: 40px;
  font-weight: 500;
}
h3 {
  font-size: 32px;
  font-weight: 500;
}
h4 {
  font-size: 28px;
  font-weight: 500;
}
h5 {
  font-size: 24px;
  font-weight: 500;
}
h6 {
  font-size: 20px;
  font-weight: 500;
}
p {
  font-size: 20px;
}
small {
  font-size: 12px;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
strong {
  font-weight: 500;
}

/* Container */
.container {
  padding: 1rem 5%;
}

/* Card */
.card {
  background-color: var(--background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px var(--shadow);
}
.card-header {
  background-color: var(--card-alt);
  padding: 0.5rem 1rem;
}
.card-header + .card-body {
  border-top: 1px solid var(--border);
}
.card-body {
  background-color: var(--card);
  padding: 0.5rem 1rem;
}
.card-body + .card-footer {
  border-top: 1px solid var(--border);
}
.card-footer {
  background-color: var(--card-alt);
  padding: 0.5rem 1rem;
}
a:has(.card-button) {
  color: black;
  text-decoration: none;
}
.card-button {
  cursor: pointer;
  transition: transform 150ms ease-in-out;
}
.card-button:hover,
.card-button:focus {
  transform: translateY(-4px);
}

/* Utilities */

/* Width */
.w-full {
  width: 100%;
}
.w-5 {
  width: 80px;
}
.w-10 {
  width: 160px;
}
.w-20 {
  width: 320px;
}
.w-30 {
  width: 480px;
}
.w-40 {
  width: 640px;
}
.w-50 {
  width: 800px;
}
.max-w-5 {
  max-width: 80px;
}
.max-w-10 {
  max-width: 160px;
}
.max-w-20 {
  max-width: 320px;
}
.max-w-30 {
  max-width: 480px;
}
.max-w-40 {
  max-width: 640px;
}
.max-w-50 {
  max-width: 800px;
}

/* Flex */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: start;
}
.items-end {
  align-items: end;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.justify-between {
  justify-content: space-between;
}
.gap-px {
  gap: 1px;
}
.gap-2px {
  gap: 2px;
}
.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-8 {
  gap: 32px;
}
.gap-16 {
  gap: 64px;
}
.gap-24 {
  gap: 96px;
}
.gap-32 {
  gap: 128px;
}

/* Buttons */

/* Main */
.btn {
  padding: 0.25rem 1rem;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
/* Primary */
.btn-primary {
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: opacity 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.btn-primary:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
.btn-primary:not(:disabled):hover {
  opacity: 80%;
}
.btn-primary:not(:disabled):focus {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.75), 0 2px 4px rgba(0, 0, 0, 0.75),
    -2px 0 4px rgba(0, 0, 0, 0.75), 2px 0 4px rgba(0, 0, 0, 0.75);
}
/* Error */
.btn-error {
  background-color: #c00;
  color: #fff;
  cursor: pointer;
  transition: opacity 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.btn-error:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
.btn-error:not(:disabled):hover {
  opacity: 80%;
}
.btn-error:not(:disabled):focus {
  box-shadow: 0 -2px 4px rgba(204, 0, 0, 0.75), 0 2px 4px rgba(204, 0, 0, 0.75),
    -2px 0 4px rgba(204, 0, 0, 0.75), 2px 0 4px rgba(204, 0, 0, 0.75);
}
/* Link */
.link {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  color: #05a;
  transition: opacity 150ms ease-in-out;
  text-decoration: none;
}
.link:disabled,
.link.disabled {
  opacity: 50%;
}
.link:not(:disabled):not(.disabled):hover,
.link:not(:disabled):not(.disabled):focus {
  text-decoration: underline;
}
/* Black Link */
.link-black {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  color: #000;
  transition: opacity 150ms ease-in-out;
  text-decoration: none;
}
.link-black:disabled,
.link-black.disabled {
  opacity: 50%;
}
.link-black:not(:disabled):not(.disabled):hover,
.link-black:not(:disabled):not(.disabled):focus {
  text-decoration: underline;
}
/* Red Link */
.link-red {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  color: #c00;
  transition: opacity 150ms ease-in-out;
  text-decoration: none;
}
.link-red:disabled,
.link-red.disabled {
  opacity: 50%;
}
.link-red:not(:disabled):not(.disabled):hover,
.link-red:not(:disabled):not(.disabled):focus {
  text-decoration: underline;
}

/* Forms */

/* Form */
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/* Label */
.form-group label {
  font-size: 16px;
  position: relative;
}
.form-group.required label::after {
  position: relative;
  content: ' *';
  color: #c00;
}
/* Control */
.form-control {
  font-size: 16px;
  border: none;
  outline: none;
  background-color: #fff;
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 4px 8px;
  transition: opacity 150ms ease-in-out, border-color 150ms ease-in-out,
    box-shadow 150ms ease-in-out;
}
textarea.form-control {
  resize: vertical;
}
.form-control.error {
  border-color: #c00;
}
.form-control:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
.form-control:not(:disabled):hover {
  border-color: #000;
}
.form-control:not(:disabled):focus {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.75), 0 2px 4px rgba(0, 0, 0, 0.75),
    -2px 0 4px rgba(0, 0, 0, 0.75), 2px 0 4px rgba(0, 0, 0, 0.75);
}
.form-control.error:not(:disabled):focus {
  box-shadow: 0 -2px 4px rgba(204, 0, 0, 0.75), 0 2px 4px rgba(204, 0, 0, 0.75),
    -2px 0 4px rgba(204, 0, 0, 0.75), 2px 0 4px rgba(204, 0, 0, 0.75);
}
/* Dioalog */
.form-dialog {
  padding: 8px 16px;
}
.form-dialog p {
  font-size: 16px;
}
.form-dialog-error {
  background-color: rgba(204, 0, 0, 0.1);
  border: 1px solid #c00;
  border-radius: 8px;
  color: #c00;
}

/* Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid transparent;
  border-top-color: #000;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}
